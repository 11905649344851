
<app-header-small></app-header-small>

<section class="section-padding blog" *ngIf="blogs.length > 0">
 <div class="container">
  <div class="row">
    <div class="section-heading">
       <h3><span>Blog</span> & News</h3>
       <p>Because knowledge is meant for sharing</p>
    </div>
 </div>
 <div class="row">
    <div class="col-md-4" *ngFor="let blog of blogs">
       <div class="card">
        <a routerLink="/blog/{{blog.slug}}"><img src="{{blog.blog_banner}}" class="img-fluid" alt=""></a>
          <div class="card-body">
             <p><span>{{blog.post_date}}</span></p>
             <a routerLink="/blog/{{blog.slug}}"><h4>{{blog.heading}}</h4></a>
             
             <p [innerHTML]="blog.description"> <a *ngIf="blog.read_more" routerLink="/blog/{{blog.slug}}">Read more</a></p>
          </div>
       </div>
    </div>

 </div>
</div>
</section>
<app-newsletter></app-newsletter>
<hr>
<app-footer></app-footer>