<header class="pb-0">
    <app-header-details></app-header-details>
    <div class="container mt-4">
     <div class="row pagination">
       <div class="col-md-10">
          <ul class="list-unstyled d-flex align-items-center ">
             <li><a routerLink="/" class="text-white">Home &nbsp; &nbsp; >  &nbsp; &nbsp;</a></li>
             <li><a routerLink="/agents/{{state}}/{{city}}" class="text-white">&nbsp; &nbsp;{{state}} &nbsp; &nbsp; >&nbsp; &nbsp;</a></li>
             <li><a routerLink="/agents/{{state}}/{{city}}" class="text-white">&nbsp; &nbsp;{{city}} &nbsp; &nbsp; >&nbsp; &nbsp;</a></li>
             <li><a href="#" class="text-white select">&nbsp; &nbsp;{{agentData?.company_name}}&nbsp; &nbsp;</a></li>
          </ul>
       </div>
    </div>

    <div class="row mt-2">
       <div class="col-md-8 d-flex align-items-start">
          <div class="logo-img me-3">
             <img src="{{agentData?.photo}}" width="155" height="105" alt="">
          </div>

          <div *ngIf="agentData?.featured" class="featured position-unset rounded">Featured</div>
       </div>
    </div>
 </div>
</header>
<section class="section-padding details-page">
<div class="container">
  <div class="row">
     <div class="col-md-12">
        <h3 class="mt-4">{{agentData?.company_name}}</h3>
        <p>{{agentData?.address}}, {{city}}, {{state}}</p>
        <p>{{agentData?.about}}</p>
     </div>
  </div>
</div>
</section>
<section class="section-padding subscribe contact-form">
<div class="container">
 <div class="row">
    <div class="col-md-12 col-lg-10 mx-auto text-start">
          <div class="section-heading text-center">
             <h3 class="text-white">Get In <span>Touch</span></h3>
             <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro labore veritatis <br> molestiae autem, libero earum.</p>
          </div>
       <div class="subscribe-box p-md-5 text-start">
          <form class="row mt-4">
             <div class="mb-3 col-md-4">
               <label for="name" class="form-label text-white">Full Name</label>
               <input type="text" class="form-control" id="name">
            </div>
            <div class="mb-3 col-md-4">
               <label for="email" class="form-label text-white">Email Address</label>
               <input type="email" class="form-control" id="email" aria-describedby="emailHelp">
            </div>

            <div class="mb-3 col-md-4">
               <label for="mobile" class="form-label text-white">Phone Number</label>
               <input type="text" maxlength="10" class="form-control" id="mobile">
            </div>
            <div class="mb-3 col-md-12">
               <label for="message" class="form-label text-white">Message</label> <br>
               <textarea name="" style="padding:10px" id="message" class="w-100"></textarea>
            </div>

            <div class="col-md-3 ">
               <button type="button" id="submitBtn2" (click)="submit()" class="form-btn w-100">Submit</button>
            </div>
         </form>
      </div>
   </div>
</div>
</div>
</section>
<app-sign-up-popup></app-sign-up-popup>

<app-footer></app-footer>