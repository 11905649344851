<app-header-small></app-header-small>
 
 
 <section class="thanks-you-page py-3 py-md-5">
    <div class="container col-lg-8">
       <div class="row align-items-center">
          <div class="col-md-7">
             <div class="thankyou-content">
                <h1 class="text-dark mb-3">Thank you!</h1>
                <h5 class="text-success mb-4">Your form submitted successfully</h5>
                <p class="text-dark">{{pageData.description}}</p>
                <div class="button-box pt-md-4 pt-2">
                   <div class="btns lightblue mx-0">
                      <a routerLink="/" class="p-3" >Go To Home ⟶</a>
                   </div>         
                </div>
             </div>
         
          </div>
 
          <div class="col-md-5">
             <img src="assets/img/thank-you.png" class="img-fluid">
          </div>
       </div>
    </div>
 </section>
 
 <hr>
<app-footer></app-footer>